/** @jsxImportSource @emotion/react */
//@ts-nocheck
import { css } from "@emotion/react";
import { alpha, createTheme, darken } from "@mui/material";

export interface AppTheme {
	colors: {
		primary: string;
		primaryOpacity: string;
		background: string;
		textPrimary: string;
		textSecondary: string;
		textPrimaryDark: string;
		textSecondaryDark: string;
	};
	dimensions: {
		safe: string;
	};
	styles: {
		container: any;
		flex: any;
		spacer: any;
		divider: any;
	};
}

const primaryColor = "#00fdc7";
const backgroundColor = "#ffffff";

const textPrimaryColor = "rgba(0, 0, 0, 0.74)";
const textSecondaryColor = "rgba(0, 0, 0, 0.54)";

const textPrimaryDarkColor = "rgba(255, 255, 255, 1)";
const textSecondaryDarkColor = "rgba(255, 255, 255, 0.7)";

export const safeDimension = "720px";

export const theme: AppTheme = {
	colors: {
		primary: primaryColor,
		primaryOpacity: "rgba(51, 187, 88, .24)",
		background: backgroundColor,

		textPrimary: textPrimaryColor,
		textSecondary: textSecondaryColor,

		textPrimaryDark: textPrimaryDarkColor,
		textSecondaryDark: textSecondaryDarkColor,
	},
	dimensions: {
		safe: safeDimension,
	},
	styles: {
		container: css`
			width: 100%;
			max-width: ${safeDimension};
			margin: 0 auto;
		`,
		flex: css`
			display: flex;
			flex-direction: row;
			justify-content: center;
		`,
		spacer: css`
			flex: 1;
		`,
		divider: css`
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.12);
			margin: 24px 0px;
		`,
	},
};

export const materialTheme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					color: "rgba(0, 0, 0, 0.87)",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 48,
					boxShadow: "none",
				},
				paperFullScreen: {
					borderRadius: 0,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: "18px !important",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontFamily: "inherit !important",
				}),
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					whiteSpace: "nowrap",
					transform: "translate(0px, 0px) scale(0.75);",
				}),
			},
		},
		MuiList: {
			styleOverrides: {
				padding: {
					paddingTop: 0,
					paddingBottom: 0,
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					marginTop: 10,
					borderRadius: 0,
					boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					marginRight: 40,
					fontSize: 18,
					fontWeight: "bold",
					textTransform: "capitalize",
					textAlign: "left",
					alignItems: "flex-start",
					justifyContent: "flex-start",
					lineHeight: "31px",
					padding: 0,
					minWidth: "0 !important",
					marginBottom: 20,
					color: "inherit",
					"&.Mui-selected": {
						color: "inherit",
						opacity: 1,
					},
					"&:not(.Mui-selected)": {
						opacity: 0.42,
					},
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					display: "none",
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					borderCollapse: "separate",
					borderSpacing: "0px 15px",
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					overflowX: "unset",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					// boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.15);",
					"&:hover": {
						backgroundColor: "#F8FFFD",
						cursor: "pointer",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: 20,
					borderBottom: "none",
				},
				head: {
					backgroundColor: "#F8FFFD !important",
					fontSize: 16,
					fontWeight: 700,
					letterSpacing: "0.1px",
					color: "black",
				},
			},
		},
	},
});
