import axios from "axios";
import moment from "moment";
import getConfig from "next/config";
import type { IEventCard } from "../components/eventCard";
import type { IEventDetail } from "../features/event/single/view/interfaces/IEventDetail";
import type { MailCampaignStatus } from "../features/marketing/components/MainPage/MailCampaignsTable";
import type {
	Campaign,
	CampaignSender,
	CreateCampaignData,
	GetAllCampaignsQueryParams,
} from "../features/marketing/queries/campaigns/campaigns";
import type {
	GetListContactsByListIdQueryParams,
	ListContactResponse,
} from "../features/marketing/queries/contacts/list";
import type { ContactsListsResponse, GetAllListsQueryParams } from "../features/marketing/queries/contacts/lists";
import { buildApiWithQueryParams, fasterFetch } from "../utils";
const { publicRuntimeConfig } = getConfig();
const { BACKEND_API_URL, BACKEND_NOWR_API_URL } = publicRuntimeConfig;
const hostname = BACKEND_NOWR_API_URL;
const hostnameWEB = BACKEND_API_URL; // api/v1/

export const API = {
	proxy: {
		gmaps: `${hostnameWEB}proxy/gmaps`,
	},

	aws: {
		s3credentials: `${hostname}api/s3credentials`,
	},
	ticket: {
		create: `${hostnameWEB}ticket`,
		list: `${hostnameWEB}ticket`,
		single: `${hostnameWEB}ticket/`,
		delete: `${hostnameWEB}ticket/`,
	},
	ticketEvent: {
		list: hostnameWEB + "ticket-event/event/",
		single: hostnameWEB + "ticket-event/",
		create: hostnameWEB + "ticket-event",
		edit: hostnameWEB + "ticket-event",
		delete: hostnameWEB + "ticket-event/",
	},
	user: {
		register: hostname + "api/register",
		login: hostname + "api/authenticate",
		loginFacebook: hostname + "api/register/facebook",
		loginGoogle: hostname + "api/register/google",
		loginApple: hostname + "api/register/apple",
		getProfile: hostname + "api/profile",
		publicGetProfile: hostname + "api/public/nowr-web/profile/",
		getProfilePerAlias: hostname + "api/profile/alias",
		getProfilePerAliasPublic: `${hostname}api/public/profile/alias`,
		updateProfile: hostname + "api/profile",
		changePassword: hostname + "api/account/change_password",
		follow: hostname + "api/user/follow/",
		unfollow: hostname + "api/user/unfollow/",
		followers: hostname + "api/users/",
		following: hostname + "api/users/",
		activateEmail: hostname + "api/register/confirm/",
		publicSearch: hostname + "api/public/nowr-web/searchUsers",
		search: hostname + "api/searchUsers",
		delete: hostname + "api/user/delete",
		resetPassword: hostname + "api/account/reset_password/init",
	},
	activities: {
		following: hostname + "api/activities/following",
		you: hostname + "api/activities/me",
	},
	event: {
		list: hostname + "api/nowr-web/events",
		eventsFiltered: hostname + "api/events/filter",
		publicEventsFiltered: hostname + "api/public/events/filter",
		myEvents: hostnameWEB + "event/mine",
		inviting: hostname + "api/event/",
		publicList: hostname + "api/public/nowr-web/events",
		publicSingle: hostname + "api/public/events",
		publicGetUserProfileEvents: hostname + "api/public/nowr-web/events/user-profile/",
		publicGetUserProfileEventsByKey: hostname + "api/public/events/user-profile",
		single: hostname + "api/events",
		getUserProfileEvents: hostname + "api/events/user-profile",
		create: hostname + "api/events",
		edit: hostname + "api/events",
		canDelete: hostnameWEB + "event/can-delete/",
		delete: hostname + "api/event/terminate",
		getCollaborators: hostname + "api/event/",
		editCollaborators: hostname + "api/event/",
		checkins: hostname + "api/eventCheckins/",
		sold: {
			downloadUriBase: hostnameWEB + "sold/",
		},
		invitations: (eventId: string) => hostname + `api/event/${eventId}/invitations`,
	},
	checkins: {
		base: hostname + "api/checkins/",
	},
	provinces: {
		getAll: hostname + "api/provinces",
	},
	picture: {
		create: `${hostname}api/pictures`,
		edit: `${hostname}api/pictures`,
		delete: `${hostname}api/pictures`,
		setMain: `${hostname}api/pictures/set-main`,
	},
	like: {
		create: hostname + "api/loves",
		remove: hostname + "api/unloves",
	},
	message: {
		create: hostname + "api/messages",
	},
	checkin: {
		create: hostname + "api/checkin",
		remove: hostname + "api/checkout",
	},
	purchase: {
		current: hostnameWEB + "purchases/current",
		past: hostnameWEB + "purchases/past",
		count: hostnameWEB + "purchases/count",
		create: hostnameWEB + "purchases",
		single: hostnameWEB + "purchases/",
		delete: hostnameWEB + "purchases/",
		updateTicket: hostnameWEB + "purchases/ticket/",
		contactMerchant: hostname + "api/nowr-web/events/",
	},
	channel: {
		publicList: hostname + "api/public/channels",
	},
	methods: {
		channels: {
			list: async () => {
				return (await fasterFetch(API.channel.publicList)) as Channel[];
			},
		},
		campaigns: {
			getCampaignSender: async (token: string) => {
				return await axios.get<CampaignSender>(`${hostname}api/tool-marketing/senders`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
			getAllCampaigns: async (params: GetAllCampaignsQueryParams, token: string) => {
				return await axios.get<Campaign[]>(`${hostname}api/tool-marketing/campaigns`, {
					headers: {
						"X-Auth-Token": token,
					},
					params,
				});
			},
			createSender: async (email: string, name: string, token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/senders`,
					{ name, email },
					{ headers: { "X-Auth-Token": token } }
				);
			},
			getCampaignTemplates: async (token: string) => {
				return await axios.get(`${hostname}api/tool-marketing/available-templates`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
			getCampaignById: async (campaignId: number, token: string) => {
				return await axios.get<Campaign>(`${hostname}api/tool-marketing/campaigns/${campaignId}`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
			createCampaign: async (campaignData: CreateCampaignData, token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/campaigns`,
					{ ...campaignData },
					{ headers: { "X-Auth-Token": token } }
				);
			},
			editCampaign: async (campaignId: number, campaignData: Omit<CreateCampaignData, "templateId">, token: string) => {
				return await axios.put(
					`${hostname}api/tool-marketing/campaigns/${campaignId}`,
					{ ...campaignData },
					{ headers: { "X-Auth-Token": token } }
				);
			},
			editCampaignStatus: async (campaignId: number, status: MailCampaignStatus, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/campaigns/${campaignId}/status/${status}`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			getCampaignReport: async (campaignId: number, token: string) => {
				return await axios.get<any>(`${hostname}api/tool-marketing/campaigns/${campaignId}/report`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
			deleteCampaign: async (campaignId: number, token: string) => {
				return await axios.delete(`${hostname}api/tool-marketing/campaigns/${campaignId}`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
			sendNow: async (campaignId: number, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/campaigns/${campaignId}/send-now`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			suspendCampaign: async (campaignId: number, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/campaigns/${campaignId}/suspend`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			rescheduleCampaign: async (campaignId: number, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/campaigns/${campaignId}/queue`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			validateOTP: async (otp: string, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/senders/validate/${otp}`,
					{
						otp,
					},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			resendCode: async (token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/senders/restart-validation`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
		},
		dm: {
			broadcastDm: async (message: string, listId: number, token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/direct-message`,
					{
						message,
						listId,
					},
					{
						headers: {
							"X-Auth-Token": token,
						},
					}
				);
			},
		},
		contactList: {
			getAllLists: async (params: GetAllListsQueryParams, token: string) => {
				return await axios.get<ContactsListsResponse[]>(`${hostname}api/tool-marketing/lists`, {
					params,
					headers: { "X-Auth-Token": token },
				});
			},
			addList: async (listName: string, token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/lists`,
					{ name: listName },
					{ headers: { "X-Auth-Token": token } }
				);
			},
			editList: async (listId: string, listName: string, token: string) => {
				return await axios.put(
					`${hostname}api/tool-marketing/lists/${listId}`,
					{ name: listName },
					{ headers: { "X-Auth-Token": token } }
				);
			},
			deleteList: async (listId: number, token: string) => {
				return await axios.delete(`${hostname}api/tool-marketing/lists/${listId}`, {
					headers: { "X-Auth-Token": token },
				});
			},
			getListById: async (listId: number, token: string) => {
				return await axios.get<ContactsListsResponse>(`${hostname}api/tool-marketing/lists/${listId}`, {
					headers: { "X-Auth-Token": token },
				});
			},
			getListContactsByListId: async (listId: number, params: GetListContactsByListIdQueryParams, token: string) => {
				return await axios.get<ListContactResponse[]>(`${hostname}api/tool-marketing/lists/${listId}/contacts`, {
					headers: { "X-Auth-Token": token },
					params,
				});
			},
			createContact: async (firstName: string, lastName: string, email: string, token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/contacts`,
					{ firstName, lastName, email },
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			addContactsToList: async (listId: number, contactsIds: number[], token: string) => {
				return await axios.post(
					`${hostname}api/tool-marketing/lists/${listId}/contacts`,
					{ contactIds: contactsIds },
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			deleteContactsFromList: async (listId: number, contactsIds: number[], token: string) => {
				return await axios.delete(`${hostname}api/tool-marketing/lists/${listId}/contacts`, {
					headers: {
						"X-Auth-Token": token,
					},
					data: { contactIds: contactsIds },
				});
			},
			blockContact: async (listId: number, contactId: number, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/lists/${listId}/contacts/${contactId}/block`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			unblockContact: async (listId: number, contactId: number, token: string) => {
				return await axios.patch(
					`${hostname}api/tool-marketing/lists/${listId}/contacts/${contactId}/active`,
					{},
					{
						headers: { "X-Auth-Token": token },
					}
				);
			},
			getContactsCSV: async (listId: number, token: string) => {
				return await axios.get(`${hostname}api/tool-marketing/lists/${listId}/contacts/csv`, {
					headers: { "X-Auth-Token": token },
				});
			},
			getMyLists: async (token: string) => {
				return await axios.get<ContactsListsResponse[]>(`${hostname}api/tool-marketing/my-lists`, {
					headers: {
						"X-Auth-Token": token,
					},
				});
			},
		},
		ticketEvent: {
			getTicketsForEventWithDate: async (token: string, eventId: number, date?: string) => {
				const url = buildApiWithQueryParams(API.ticketEvent.list + eventId, { date });
				return await fasterFetch(url);
			},
		},
		event: {
			getEventFromId: async (eventId: string) => {
				return await fasterFetch(`${hostname}api/public/events/${eventId}`, {
					method: "GET",
				});
			},
			getEventCollaborators: async (eventId: string, token: string) => {
				return await fasterFetch(`${hostname}api/events/${eventId}/collaborator`, {
					method: "GET",
					headers: {
						"x-auth-token": token,
					},
				});
			},
			getEventPromoters: async (eventId: string, token: string) => {
				return await axios
					.get(`${hostname}api/events/${eventId}/promoters`, {
						headers: {
							"x-auth-token": token,
						},
					})
					.then((res) => res?.data);
			},
			createEventPromoter: async (eventId: string, userId: number, token: string) => {
				return await axios
					.post(
						`${hostname}api/events/${eventId}/promoters`,
						{
							userId: userId,
							percentage: 0,
							fixedFee: 0,
							maxTicketsAllowed: 1000,
						},
						{
							headers: {
								"x-auth-token": token,
							},
						}
					)
					.then((res) => res?.data);
			},
			disableEventPromoter: async (eventId: string, promoterId: string, token: string) => {
				return await axios.post(
					`${hostname}api/events/${eventId}/promoters/${promoterId}/disable`,
					{},
					{
						method: "POST",
						headers: {
							"x-auth-token": token,
						},
					}
				);
			},
			getEventFromIdWithToken: async (eventId: string, token: string) => {
				return (await fasterFetch(`${hostname}api/events/${eventId}`, {
					method: "GET",
					headers: {
						"x-auth-token": token,
					},
				})) as IEventDetail;
			},
			getUserCreatedEvents: async (token: string, userId: number, page: number = 0, perPage: number = 50) => {
				const url = buildApiWithQueryParams(`${API.event.getUserProfileEvents}/${userId}`, {
					page,
					size: perPage,
					paginate: true,
					eventFilter: "CREATED",
				});
				return await axios.get(url, { headers: { "X-Auth-Token": token } });
			},
		},
		getSimpleUser: async (token?: string) => {
			return await fasterFetch(API.user.getProfile, {
				method: "GET",
				headers: {
					"X-Auth-Token": token ?? "",
				},
			});
		},
		promoterTracking: {
			getPromoterEventClicksCount: async (uuid: string, token: string) => {
				return await axios.get(`${hostname}api/tracking/click/count?relatedUuid=${uuid}`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
			getPromoterEventSalesCount: async (uuid: string, token: string) => {
				return await axios.get(`${hostname}api/tracking/sale/count?relatedUuid=${uuid}`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
			getPromoterClickTracking: async (promoterId: number, token: string, params?: string) => {
				return await axios.get(
					`${hostname}api/tracking/click/aggregate-promoter/${promoterId}${params ? `?${params}` : ""}`,
					{
						headers: {
							"x-auth-token": token,
						},
					}
				);
			},
			getPromoterSalesTracking: async (promoterId: number, token: string, params?: string) => {
				return await axios.get(
					`${hostname}api/tracking/sale/aggregate-promoter/${promoterId}${params ? `?${params}` : ""}`,
					{
						headers: {
							"x-auth-token": token,
						},
					}
				);
			},
			getPromoterSoldTickets: async (eventId: number, promoterId: number, token: string) => {
				return await axios.get(`${hostname}api/events/${eventId}/promoters/${promoterId}/sale-tracking-details`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
		},
		eventTracking: {
			postEventClickTracking: async (uuid: string) => {
				axios.post(`${hostname}api/tracking/click`, { relatedUuid: uuid });
			},
			getEventClickTracking: async (eventId: number, token: string, params?: string) => {
				return await axios.get(`${hostname}api/tracking/click/aggregate-event/${eventId}${params ? `?${params}` : ""}`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
			getEventSalesTracking: async (eventId: number, token: string, params?: string) => {
				return await axios.get(`${hostname}api/tracking/sale/aggregate-event/${eventId}${params ? `?${params}` : ""}`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
			getEventSoldTickets: async (eventId: number, token: string) => {
				return await axios.get(`${hostname}api/events/${eventId}/sale-tracking-details`, {
					headers: {
						"x-auth-token": token,
					},
				});
			},
		},
		getEvidenceEventsForUser: async (token: string, userId: number) => {
			return await fasterFetch(`${hostname}api/users/${userId}/favorite-events`, {
				method: "GET",
				headers: { "X-auth-token": token },
			});
		},
		getNonTerminatedInEvidenceEventsForUser: async (token: string, userId: number, key?: string) => {
			let results: any[] = [];
			try {
				results = await fasterFetch(`${hostname}api/users/${userId}/favorite-events?key=${key}`, {
					method: "GET",
					headers: { "X-auth-token": token },
				});
			} catch (error) {}

			const today = moment();

			return (results || []).filter((x: IEventCard) => moment(x.endDate).isAfter(today));
		},
		getPublicNonTerminatedInEvidenceEventsForUser: async (token: string, userId: number, key?: string) => {
			let results: any[] = [];
			try {
				results = await fasterFetch(`${hostname}api/public/users/${userId}/favorite-events?key=${key}`, {
					method: "GET",
					headers: { "X-auth-token": token },
				});
			} catch (error) {}

			const today = moment();

			return (results || []).filter((x: IEventCard) => moment(x.endDate).isAfter(today));
		},
		addToInEvidenceEvent: async (token: string, eventId: number) => {
			const results = await fasterFetch(`${hostname}api/events/${eventId}/favorite-events`, {
				method: "POST",
				headers: { "X-auth-token": token },
			});

			return results;
		},
		deleteFromInEvidenceEvent: async (token: string, eventId: number) => {
			const results = await fasterFetch(`${hostname}api/events/${eventId}/favorite-events`, {
				method: "DELETE",
				headers: { "X-auth-token": token },
			});

			return results;
		},
	},
};

type Channel = {
	name: string;
	id: number;
	url: string;
	secondUrl: string;
};
