/** @jsxImportSource @emotion/react */

import type { CSSObject } from "@emotion/react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { isFunction } from "lodash";
import { ReactNode, useMemo } from "react";
import useBetterMediaQuery from "../../hooks/useBetterMediaQuery";
import NowrIcon from "../icons/NowrIcon";
import ResponsiveDialog, { ResponsiveDialogProps } from "./ResponsiveDialog";

type RoundedDialogProps = {
	title?: string;
	header?: ReactNode | ((props: { onClose: () => any; closeIcon: ReactNode }) => ReactNode);
	open: boolean;
	onClose: () => any;
	noHeader?: boolean;
	noCloseIcon?: boolean;
	customCloseIcon?: (onClose: () => any) => React.ReactNode;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	buttons?: React.ReactNode;
	[key: string]: any;
	containerCss?: CSSObject;
} & ResponsiveDialogProps;

const RoundedDialog: React.FC<RoundedDialogProps> = ({
	title,
	open,
	onClose,
	noHeader,
	maxWidth,
	children,
	noCloseIcon,
	customCloseIcon,
	buttons,
	containerCss,
	BottomSheetProps,
	header,
	...props
}) => {
	const isMd = useBetterMediaQuery("md");

	const footer = buttons && (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			gap={2}
			sx={{ paddingTop: { xs: 0, md: 12 } }}
		>
			{buttons}
		</Stack>
	);

	const closeIcon = useMemo(() => {
		if (customCloseIcon) return customCloseIcon(onClose);

		return (
			<IconButton size={"medium"} onClick={onClose} css={{ marginRight: -8 }}>
				<NowrIcon name="close" color="secondary" size={24} iconCss={{ paddingTop: 0 }} />
			</IconButton>
		);
	}, [onClose, customCloseIcon]);

	const innerHeader = header
		? isFunction(header)
			? header({ onClose, closeIcon })
			: header
		: !noHeader && (
				<div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: isMd ? 16 : 0 }}>
					<Typography variant="h6" children={title ?? ""} style={{ fontWeight: "bold" }} />
					{!noCloseIcon ? closeIcon : <div css={{ height: 40 }} />}
				</div>
		  );

	return (
		<ResponsiveDialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth || "sm"}
			fullWidth={true}
			BottomSheetProps={{ ...BottomSheetProps, footer, header: innerHeader }}
			{...props}
		>
			<Box
				sx={{
					padding: {
						xs: 5,
						md: 10,
					},
				}}
				css={{ ...containerCss }}
			>
				{isMd && innerHeader}
				{children}
				{isMd && footer}
			</Box>
		</ResponsiveDialog>
	);
};
export default RoundedDialog;
