/** @jsxImportSource @emotion/react */

import { Button, Grid, Stack, Typography } from "@mui/material";
import { OptionsObject, WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { theme } from "../utils/theme";
import RoundedDialog from "./dialogs/RoundedDialog";
import NowrIcon from "./icons/NowrIcon";

export interface MLDialogParams {
	title?: string;
	subtitle?: string;
	message?: string | any;
	noHeader?: boolean;
	onPositiveClick?: () => boolean | void; // return true for not closing modal
	onNegativeClick?: () => boolean | void; // return true for not closing modal
	positiveText?: string;
	positiveColor?: string;
	negativeText?: string;
	forceMode?: boolean;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
	state = MLDialog.defaultState;

	static __uniqueRef: MLDialog;

	static defaultState = {
		theme: theme,
		open: false,
		title: "",
		subtitle: "",
		noHeader: false,
		message: "",
		component: null,
		onPositiveClick: () => false,
		onNegativeClick: null,
		positiveText: "Ok",
		positiveColor: undefined,
		negativeText: "Annulla",
		forceMode: false,
	};

	static showModal(title: string, subtitle: string, message: string | any, params?: MLDialogParams) {
		MLDialog.__uniqueRef.showModal(title, subtitle, message, undefined, params);
	}

	static showModalWithComponent(component: React.ReactNode, params?: MLDialogParams) {
		//@ts-ignore
		MLDialog.__uniqueRef.showModal(undefined, undefined, undefined, component, params);
	}

	static hideModal() {
		MLDialog.__uniqueRef.hideModal();
	}

	static showSnackbar(message: string, options?: OptionsObject) {
		MLDialog.__uniqueRef.showSnackbar(message, { anchorOrigin: { horizontal: "right", vertical: "bottom" }, ...options });
	}

	static hideSnackbar(key: string | number | undefined) {
		MLDialog.__uniqueRef.hideSnackbar(key);
	}

	constructor(props: WithSnackbarProps) {
		super(props);
		MLDialog.__uniqueRef = this;
	}

	showModal(
		title: string,
		subtitle: string | any,
		message: string | any,
		component: React.ReactNode,
		params?: MLDialogParams
	) {
		this.setState(
			Object.assign({}, MLDialog.defaultState, params, {
				title,
				subtitle,
				message,
				component,
				open: true,
			})
		);
	}

	hideModal = () => {
		this.setState({ open: false });
	};

	showSnackbar(message: String, options?: OptionsObject) {
		this.props.enqueueSnackbar(message, options);
	}

	hideSnackbar(key: string | number | undefined) {
		this.props.closeSnackbar(key);
	}

	render() {
		const width = (typeof window !== `undefined` && window.innerWidth) || 1920;
		const isMobile = width < 599.99;
		return (
			<RoundedDialog
				title={this.state.title}
				open={this.state.open}
				noHeader={this.state.noHeader}
				fullScreen={width < 599.99}
				onClose={() => !this.state.forceMode && this.hideModal()}
			>
				<Grid container alignItems="center" direction="column" padding={3}>
					{!this.state.component && (
						<Typography
							dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
							component="div"
							style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}
						/>
					)}
					{!this.state.component && (
						<Typography
							dangerouslySetInnerHTML={{ __html: this.state.message }}
							component="div"
							style={{ marginTop: 20, marginBottom: 60, fontSize: 18, textAlign: "center" }}
						/>
					)}

					{this.state.component}
					{(Boolean(this.state.onPositiveClick) || this.state.onNegativeClick) && (
						<Stack
							direction="row"
							justifyContent="center"
							spacing={2}
							marginTop={8}
							sx={{
								width: "100%",
							}}
						>
							{this.state.onNegativeClick && (
								<Button
									size={isMobile ? "medium" : "large"}
									variant="outlined"
									startIcon={<NowrIcon name="close" />}
									color="secondary"
									children={this.state.negativeText}
									onClick={() => {
										//@ts-ignore
										if (this.state.onNegativeClick && this.state.onNegativeClick()) {
											console.log(this.state.onNegativeClick);
											return;
										}
										this.hideModal();
									}}
								/>
							)}
							{this.state.onPositiveClick && (
								<Button
									variant="contained"
									sx={{
										background: this.state.positiveColor,
										...(this.state.positiveColor ? { color: (theme) => theme.palette.getContrastText("#FF44A8") } : {}),
									}}
									size={isMobile ? "medium" : "large"}
									startIcon={<NowrIcon name="check_broken" />}
									children={this.state.positiveText}
									onClick={() => {
										if (this.state.onPositiveClick && this.state.onPositiveClick()) {
											return;
										}
										this.hideModal();
									}}
								/>
							)}
						</Stack>
					)}
				</Grid>
			</RoundedDialog>
		);
	}
}

export const __MLDialog = withSnackbar(MLDialog);
