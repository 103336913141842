import { Dialog, dialogClasses, DialogProps, Slide, useMediaQuery, useTheme } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useCallback } from "react";
import { BottomSheet, BottomSheetProps } from "react-spring-bottom-sheet";

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export type ResponsiveDialogProps = {
	isRightSheet?: boolean;
	BottomSheetProps?: Omit<BottomSheetProps, "open" | "children" | "onDismiss">;
} & DialogProps;
const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
	BottomSheetProps,
	open,
	onClose,
	children,
	isRightSheet,
	sx,
	...otherProps
}) => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));

	const snapPoints = useCallback(({ minHeight, maxHeight }: { minHeight: number; maxHeight: number }) => {
		return [maxHeight * 0.75, maxHeight - 80];
	}, []);

	const defaultSnap = useCallback(({ lastSnap, snapPoints }) => {
		return lastSnap ?? Math.max(...snapPoints);
	}, []);

	return isMd ? (
		<Dialog
			open={open}
			onClose={onClose}
			children={children}
			{...(!isRightSheet && {
				sx: sx,
			})}
			{...(isRightSheet && {
				TransitionComponent: Transition,
				transitionDuration: 400,
				position: "relative",
				PaperProps: {
					sx: {
						maxHeight: "100vh",
						height: "100vh",
						borderRadius: 6,
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
						marginRight: 0,
						maxWidth: "max(90vw, 900px)",
					},
				},
				sx: {
					...sx,
					[`& .${dialogClasses.container}`]: {
						justifyContent: "flex-end",
					},
				},
			})}
			{...otherProps}
		/>
	) : (
		<BottomSheet
			open={open}
			children={children}
			onDismiss={() => onClose?.({}, "backdropClick")}
			snapPoints={snapPoints}
			defaultSnap={defaultSnap}
			expandOnContentDrag
			className="z-[1400]"
			{...BottomSheetProps}
		/>
	);
};
export default ResponsiveDialog;
